<template>
  <v-card flat>
    <v-card-text>
      <v-form :model="form" ref="form" class="py-4">
        <v-row v-for="field in curItem['fields']" :key="field">
          <v-col class="px-0" cols="12">
            <v-text-field
              solo
              :prepend-inner-icon="
                field === 'login_name' || field === 'username'
                  ? 'mdi-account'
                  : 'mdi-lock-outline'
              "
              :label="
                field === 'password' ? passwordPlaceholder : usernamePlaceholder
              "
              :type="field === 'password' && !show ? 'password' : 'text'"
              v-model="loginData[field]"
              required
              :rules="rules.isFull"
              @keyup.enter="FinishLogin(curItem)"
            >
              <v-icon
                v-if="field === 'password'"
                slot="append"
                @click="() => (show = !show)"
              >
                {{ show ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="showCaptcha">
          <v-col class="px-0" cols="12" style="position: relative">
            <v-text-field
              solo
              label="请输入图形验证码"
              prepend-inner-icon="mdi-check-circle-outline"
              v-model="verify_code"
              required
              :rules="rules.isFull"
            ></v-text-field>
            <img
              style="position: absolute; top: 4px; right: 4px"
              :src="captcha_image"
              alt=""
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-3" style="cursor: pointer">
          <div>
            <span
              v-if="showUseInstruction"
              class="text-caption unselect"
              @click="noticeEvent"
              :style="{ color: fontColor }"
            >
              {{ noticeTitle }}
            </span>
          </div>
          <div>
            <span class="text-caption" v-if="0"> 忘记密码 </span>
          </div>
        </div>
        <v-row>
          <v-col cols="12" class="px-0 mt-2">
            <v-btn
              class="py-5"
              height="40"
              @click="FinishLogin(curItem)"
              :style="{
                backgroundColor: highlightColor,
                color: fontColor,
                borderRadius: '8px',
                width: '100%',
                fontSize: '18px'
              }"
            >
              {{ loginSubmit }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { api_request } from "@/util/network";
import { encryptPassword } from "@/util/jwes";
import { saveLastAccount } from "@/util/fido_util";

export default {
  name: "PcPasswordCard",
  props: ["sonData", "curId", "curItem", "fromType"],
  data() {
    let isFull = value => {
      let isfull = value ? true : false;
      return isfull;
    };

    return {
      rules: {
        isFull: [v => isFull(v) || "此项为必填项"]
      },
      show: false,
      form: {},
      loginData: {},
      verify_code: "",
      showUseInstruction: false,
      usernamePlaceholder: "",
      passwordPlaceholder: "",
      loginSubmit: "",
      noticeTitle: "",
      highlightColor: "",
      fontColor: "",
      showCaptcha: false,
      captcha_image: "",
      captcha_challenge: "",
      challenge: "",
      exchange_key: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 图形验证码登录
    captchaLogin(curItem) {
      if (this.$refs.form.validate()) {
        let loginUserNameKey = curItem.fields[0];
        let loginPwsKey = curItem.fields[1];
        let credentials = {
          [loginUserNameKey]: this.loginData[loginUserNameKey],
          [loginPwsKey]: this.loginData[loginPwsKey],
          captcha_challenge: this.captcha_challenge,
          captcha_code: this.verify_code
        };

        if (credentials["password"] !== undefined) {
          credentials["password"] = encryptPassword(
            this.exchange_key,
            this.challenge,
            credentials["password"]
          );
        }

        this.$http
          .post(`api/source/${curItem.id}/finish`, credentials)
          .delegateTo(api_request)
          .then(data => {
            if (this.fromType === "WECHAT_RZKC") {
              this.$emit("showAccountEvent", {
                showAccount: true,
                accountInfo: data
              });
            } else {
              this.$store.commit("auth_success", data);
              saveLastAccount(data);
              if (data["return_url"]) {
                window.location = data["return_url"];
              } else {
                this.$router.push({ path: "/" });
              }
              return "登录成功";
            }
          })
          .catch(({ code }) => {
            throw `登录失败：${this.$t("api." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    passwordLogin(curItem, challenge, exchange_key) {
      let loginUserNameKey = curItem.fields[0];
      let loginPwsKey = curItem.fields[1];
      let credentials = {
        [loginUserNameKey]: this.loginData[loginUserNameKey],
        [loginPwsKey]: this.loginData[loginPwsKey]
      };

      if (credentials["password"] !== undefined) {
        credentials["password"] = encryptPassword(
          exchange_key,
          challenge,
          credentials["password"]
        );
      }

      this.$http
        .post(`api/source/${curItem.id}/finish`, credentials)
        .delegateTo(api_request)
        .then(data => {
          if (this.fromType === "WECHAT_RZKC") {
            this.$emit("showAccountEvent", {
              showAccount: true,
              accountInfo: data
            });
          } else {
            this.$store.commit("auth_success", data);
            saveLastAccount(data);
            if (data["return_url"]) {
              window.location = data["return_url"];
            } else {
              this.$router.push({ path: "/" });
            }
            return "登录成功";
          }
        })
        .catch(({ code }) => {
          throw `登录失败：${this.$t("api." + code)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 点击密码登录
    FinishLogin(curItem) {
      if (this.$refs.form.validate()) {
        if (this.showCaptcha) {
          this.captchaLogin(curItem);
        } else {
          this.doStart(curItem);
        }
      }
    },
    // 密码登录
    doStart(curItem) {
      if (this.$refs.form.validate()) {
        this.$http
          .get(
            `api/source/${curItem.id}/start?login_name=${
              this.loginData[curItem.fields[0]]
            }`
          )
          .delegateTo(api_request)
          .then(
            ({ challenge, exchange_key, captcha_challenge, captcha_image }) => {
              if (captcha_challenge && captcha_image) {
                this.captcha_challenge = captcha_challenge;
                this.challenge = challenge;
                this.exchange_key = exchange_key;
                this.showCaptcha = true;
                localStorage.setItem("captcha_image", captcha_image);
              } else {
                this.passwordLogin(curItem, challenge, exchange_key);
              }
            }
          )
          .catch(({ code }) => {
            throw `获取失败：${this.$t("api." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    noticeEvent() {
      this.$emit("noticeChange", true);
    },
    fetchData() {
      let {
        usernamePlaceholder,
        passwordPlaceholder,
        showUseInstruction,
        useInstructionTitle,
        loginSubmit,
        noticeDisplay,
        loginFrame: { loginFontColor, loginHighLight }
      } = this.sonData;

      this.usernamePlaceholder = usernamePlaceholder
        ? usernamePlaceholder
        : "输入用户名";
      this.passwordPlaceholder = passwordPlaceholder
        ? passwordPlaceholder
        : "输入密码";
      if (noticeDisplay === "staticState") {
        this.showUseInstruction = false;
      } else {
        this.showUseInstruction = showUseInstruction;
      }
      this.noticeTitle = useInstructionTitle;
      this.loginSubmit = loginSubmit ? loginSubmit : "登录";
      this.highlightColor = loginHighLight;
      this.fontColor = loginFontColor;
    }
  },
  watch: {
    curItem() {
      this.fetchData();
    },
    showCaptcha() {
      let captcha_image = localStorage.getItem("captcha_image");
      if (captcha_image) {
        this.captcha_image = captcha_image;
      }
    }
  }
};
</script>
